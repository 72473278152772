<template>
    <div class="col-12 header-wrap">
    <div class="row" style="background-color: #ffff0021;">
        <div class="col-2 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center">Result Indicators and year-wise activity targets</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center">Target Year</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center">Prior results 2023</p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <p class="border-bottom">Year 1 (2024)</p>
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q1</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q2</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q3</p>
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <p>Q4</p>
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <p class="border-bottom">Year 2 (2025)</p>
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q1</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q2</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q3</p>
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <p>Q4</p>
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <p class="border-bottom">Year 3 (2026)</p>
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q1</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q2</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q3</p>
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <p>Q4</p>
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <p class="border-bottom">Year 4 (2027)</p>
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q1</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q2</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q3</p>
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <p>Q4</p>
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <p class="border-bottom">Year 5 (2028)</p>
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q1</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q2</p>
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                            <p>Q3</p>
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                            <p>Q4</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 p-2 border-top border-bottom border-box custom-light-danger">
    <p>Outcome: Technology-oriented skilled workforce increased for priority and emerging industries across
        skill
        levels</p>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">DLI - 1</h5>
            <p>Skilled workers across skill levels produced for at least 10 priority and emerging sectors (i.e., advanced technical, managerial, and entry-levels), with 65% job placement for new job seekers [excluding those being counted under DLI2].</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.1</h5>
            <p>Ensure at least 80,000 people [cumulative] could improve their sector-relevant skills with certified training across all skill levels.</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2026</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.2</h5>
            <p>Job placement of at least 65% of certified new job seekers (within 6 months) [male]</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2026</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.3</h5>
            <p>Job placement of at least 65% of certified new job seekers (within 6 months) [female]</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2026</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.4</h5>
            <p>Ensure at least 120,000 people [cumulative] could improve their sector-relevant skills with certified training across all skill levels.</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2027</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.5</h5>
            <p>Job placement of at least 65% of certified new job seekers (within 6 months) [male]</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2027</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.6</h5>
            <p>Job placement of at least 65% of certified new job seekers (within 6 months) [female]</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2027</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.7</h5>
            <p>Ensure at least 160,000 people [cumulative] could improve their sector-relevant skills with certified training across all skill levels.</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2028</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.8</h5>
            <p>Job placement of at least 65% of certified new job seekers (within 6 months) [male]</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2028</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">1.9</h5>
            <p>Job placement of at least 65% of certified new job seekers (within 6 months) [female]</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2028</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-12 py-3">

</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">DLI - 2</h5>
            <p>Women and people from socially disadvantaged groups with employable work and life skills increased through specially targeted programs (e.g., people with disabilities, orphans, transgender people, people from small ethnic communities)</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">2.1</h5>
            <p>Ensure at least 12,500 women and socially disadvantaged people [cumulative] gained employable skills, including life skills and entrepreneurship training.</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2026</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">2.2</h5>
            <p>Ensure at least 20,000 women and socially disadvantaged people [cumulative] gained employable skills, including life skills and entrepreneurship training.</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2027</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 border-top border-bottom">
    <div class="row">
        <div class="col-2 p-2  border-right">
            <h5 class="m-0">2.3</h5>
            <p>Ensure at least 26,500 women and socially disadvantaged people [cumulative] gained employable skills, including life skills and entrepreneurship training.</p>
        </div>
        <div class="col-1 p-2 border-right d-flex align-items-center justify-content-center">
            <p class="text-center">2028</p>
        </div>
        <div class="col-1 p-2 border-box border-right d-flex align-items-center justify-content-center">
            <p class="text-center"></p>
        </div>
        <div class="col-8 text-center border-box p-0">
            <div class="d-flex h-100">
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
                <div class="border-right" style="width:20%; display: flex; flex-direction: column;">
                    <div class="row p-0 m-0 flex-grow-1">
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 border-right d-flex align-items-center justify-content-center">
                        </div>
                        <div class="col-3 d-flex align-items-center justify-content-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<style>
.border-right {
    border-right: 1px solid #bbbbbb !important;
}
.border-top {
    border-top: 1px solid #bbbbbb !important;
}
.border-bottom {
    border-bottom: 1px solid #bbbbbb !important;
}
</style>


